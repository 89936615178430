#searchWrapper {
    height: auto;
    background-color: #333F48;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 30px;

}

#searchWrapper input {
    padding: 10px 15px;
    margin-bottom: 15px;
    border-radius: 20px;
    border: none;
    width: 40%;
}

#searchWrapper h1 {
    font-size: 2rem;
    font-weight: 600;
    color: white;
    text-align: center;
}

#inputWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;    
}

@media only screen and (max-width: 725px) {
    #inputWrapper {
        justify-content: center;
        flex-direction: column;
    }

    #searchWrapper input {
        width: auto;
    }


}