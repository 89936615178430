button {
    background-color:#3A8DDE;
    border: none;
    border-radius: 2rem;
    color: white;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    letter-spacing: .05em;
    font-weight: 600;
    padding: 1rem 2rem;
    margin: 0px auto;
    transition: transform .25s ease;
    text-transform: uppercase;
}

button:hover {
    transform: translateY(-2px);
    
}

button:focus {
    outline: none;
}

.startPhase {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.questionPhase {
    display: flex;
    flex-direction: column;
}

.endPhase {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#endForm {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 30px 0px 15px 0px;
}

#endForm input {
    border: none;
    border-radius: 20px;
    padding: .75rem 1rem;
    margin: 10px 10px;

}

#endForm input:focus {
    outline: none;
}

#vennContainer {
    background-color: white;
    padding: 30px;
    display: flex;
    color: #333F48;
    border-radius: 20px;
    margin-bottom: 60px;
    width: 600px;
    
}

#vennDiagramContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#tooltip {
    margin-left: 30px;
}

.light {
    color: white;
}



.center {
    text-align: center;
}

h1 {
    font-size: 3rem;
    margin-bottom: .5em;

}

h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.body {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
}

.vennSection {
    stroke: white;
    stroke-width: 5px;
    opacity: .7;
    transition: opacity .25s ease;
}

.vennSection:hover{
    opacity: 1;
    
}

#thankYouMessage {
    padding: 0px 30px;
}

@media only screen and (max-width: 725px) {
    #endForm {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        width: 80%;
    }

    #vennDiagramContainer {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        padding: 10px 10px;
    }

    #vennContainer {
        display: flex;
        flex-direction: column;
        align-content: center;
        border-radius: 0;
        width: 100%;
    }

    #endForm {
        margin-top: 30px;
        padding: 0px 10px;
    }

    #endForm input {
        margin: 0px 0px 10px 0px;
        
    }

    #tooltip {
        margin: 0px 30px;
    }

    button:hover {
        transform: none;
        
    }

    .startPhase {
        margin-top: 100px;
    }

     
}