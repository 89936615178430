.question {
  font-weight: 600;
  text-align: center;
  font-size: 2rem;
  padding: 0px 30px;
}

@media only screen and (max-width: 725px) {
  .question {
    margin-top: 120px;
    font-size: 1.5rem;
  }
}
