.choiceButtonWrapper {
    display: flex;
    
    align-content: space-around;
    position: absolute;
    bottom: 60px;
     left: 0px;
    right: 0px; 
    justify-content: center;
}


.choiceButton {
    margin: 0px 10px;
    text-align: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .75rem;
}

@media only screen and (max-width: 725px) {
    .choiceButtonWrapper {
        flex-direction: column;
        width: 100%;
        align-items: center;
        bottom: 20px;
    }

    .choiceButton {
        width: 80%;
        border-radius: 10px;
        height: 40px;
        margin-bottom: 10px;
        font-size: 1rem;
    }
}