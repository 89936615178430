.submission {
    background-color: white;
    margin: 10px 10px;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 20px rgba(0,0,0,.2);
    flex-basis: 300px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px grey;
    margin-bottom: 30px;
    width: 100%;
}

.scores {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.score {
    padding: 5px 10px;
    border-radius: 10px;
    margin: 5px 5px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
}

.companyName {
    text-align: right;
    margin-left: 15px;
}

#viewQuestions {
    color: black;
    background-color: white;
    border: 1px solid grey;
    margin: 15px 0px;
}

#viewQuestions:hover {
    background-color: rgb(233, 233, 233);
    cursor: pointer;
}

#facQuestion {
    margin: 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 1px solid lightgray;
}

#facQuestionScore {
    font-weight: 900;
    margin-left: 30px;
}