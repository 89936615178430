body {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

#appContainer {
  position: relative;
  background-color: #333F48;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 60px;
  width: 70vw;
  min-width: 600px;
  min-height: 600px;
  border-radius: 20px;
  margin: 30px auto;
}

@media only screen and (max-width: 725px) { 
  #appContainer {
    padding: 0px 0px 30px 0px;
    width: 100vw;
    max-width: 100%;
    align-items: flex-start;
    min-width: 400px;
    border-radius: 0px;
    margin: 0px;
  }

}